import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'broadcastchannel-polyfill';
import ReactDOM from 'react-dom/client';
import { Suspense, lazy } from 'react';
import useApplyGlobalStyles from './hooks/useApplayStyle';
import { initGA } from './analytics';
import { sentryInit } from './helpers/sentryInit';
const MobileScreen = lazy(() => import('@/pages/MobileScreen/MobileScreen'));
const MainNavigation = lazy(() => import('@/helpers/MainNavigation'));
import { addGoogleTagManager, NoScript } from './helpers/googleTagManagerInit';
import { hotjarInit } from './helpers/hotjarInit';
import './helpers/pwaRefetch';
import '@/i18n/i18next';
import ReloadPrompt from './components/ReloadPrompt/ReloadPrompt';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from '@/redux/store';
import { ConfigComponent } from '@sobrus-com/sobrus-design-system-v2';
import { dsConfig } from './ds.config';
if (import.meta.env.VITE_REACT_APP_ENV === 'production') {
  initGA();
  addGoogleTagManager();
  hotjarInit();
}

sentryInit();
const ContainerApp: React.FC = () => {
  const { isMobile } = useApplyGlobalStyles();
  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <ConfigComponent {...dsConfig}>
            <Suspense fallback={null}>
              {import.meta.env.VITE_REACT_APP_ENV === 'production' && isMobile ? <MobileScreen /> : <MainNavigation />}
              <ReloadPrompt />
            </Suspense>
          </ConfigComponent>
        </BrowserRouter>
      </Provider>
      <NoScript />
    </>
  );
};

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<ContainerApp />);
