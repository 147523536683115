import './ReloadPrompt.scss';

import reload from './reload.svg';
import iconReload from './iconReload.svg';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Button, IconButton } from '@sobrus-com/sobrus-design-system-v2';
import { IoCloseOutline } from 'react-icons/io5';
import { useRegisterSW } from 'virtual:pwa-register/react';
import { useLocation, useNavigationType } from 'react-router-dom';

function ReloadPrompt() {
  const {
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered(r: ServiceWorkerRegistration | undefined) {
      console.info('onRegistered,r', r);
    },
    onRegisterError(error: any) {
      console.info('SW registration error', error);
    },
    onOfflineReady() {
      console.info('offfffffline');
    },
  });
  const { t } = useTranslation();

  const [openPopup, setOpenPopup] = useState(false);
  const location = useLocation();
  const navigationType = useNavigationType();

  useEffect(() => {
    if (needRefresh && location?.state?.detail === 'success') {
      updateServiceWorker(true);
    }
  }, [location.pathname, navigationType]);

  return (
    <div>
      {needRefresh ? (
        !openPopup ? (
          <div aria-hidden className='image__open_container' onClick={() => setOpenPopup(true)}>
            <img alt='' src={iconReload} />
          </div>
        ) : (
          <div className='ReloadPrompt-container'>
            <div className='ReloadPrompt-toast'>
              <div className='ReloadPrompt-headerContainer'>
                <div className='image__container'>
                  <img alt='' src={reload} />
                </div>
                <IconButton color='secondary' size='sm' onClick={() => setOpenPopup(false)}>
                  <IoCloseOutline color='#E74C3C' size={16} />
                </IconButton>
              </div>
              <div className='ReloadPrompt-message'>
                <span>{t('New update avilable')}.</span>
              </div>
              <div className='ReloadPrompt-submessage'>
                <span>{t('To avoid losing any changes made please click on "Update" besfore closing de window')}.</span>
              </div>

              <Button
                block
                onClick={() => {
                  updateServiceWorker(true);
                }}
              >
                {t('Update')}
              </Button>
            </div>
          </div>
        )
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default ReloadPrompt;
